<template>
  <div class="container" :style="'width:'+clientWidth+'px'">
    <!-- <el-row>
      <el-col :span="18" :offset="3"> -->
        <header :style="'width:'+clientWidth+'px'">
          <img src="../assets/img/logo.png" alt="" class="logo">
          <img src="../assets/img/border.png" alt="" class="border">
        </header>
        <section class="s_1">
          <img class="s_1_img" src="../assets/img/banner.png" alt="" />
        </section>
        <section>
          <article>
            <h2>Let’s Co-create a New Data Era Together!</h2>
            <p>
              Digitization accelerates the value of data not just within an
              enterprise, but across organizations, industries, and geographies.
              And we see evolving opportunities to collaborate, multiply and
              advance in the digital economy.
            </p>
            <p>
              That's why OPENHIVE embraces the vision to co-create a new data
              era by unleashing the power of data through multi-parties
              collaboration. With more joining the network, the immense value of
              data can be extracted, where one plus one multiplies beyond two.
              Because when data owners are enabled to synergize and yield new
              powers together, they unleash value unlike ever before.
            </p>
            <p>
              And with the proven technology and ingenuity of OPENHIVE, it
              breaks down the barriers to preserve data privacy and security on
              collaborative modeling, turning the impossible into possible.
            </p>
            <p>
              We are a new technology brand covering enterprise-grade solutions
              primarily focusing on multi-parties data collaboration and
              synergy, AI and privacy technology. OPENHIVE Federated Learning,
              as our prime solution, enables multi-parties modeling to unleash
              data value while preserving data privacy and security.
            </p>
            <p>
              In the digital world and data economy where collaboration changes
              everything, people and businesses can achieve much more together
              than alone. That’s the power of nexus. That’s the power of
              <strong>OPENHIVE</strong>.
            </p>
            <p>More to come!</p>
          </article>
        </section>
        <section class="q_content">
          <!-- <em class="q_text">
            OPENHIVE technology and solutions come with strong credibility and
            differentiation as proven in WeBank.
          </em> -->
          <img src="../assets/img/pink.png" alt="">
        </section>
        <section>
          <img src="../assets/img/border.png" alt="" class="border">
        </section>
        <footer>
          <img src="../assets/img/logo.png" alt="" class="logo">
          <p class="contact">Contact us - query@openhive.tech</p>
          <p class="copyright">Copyright © 2022 Openhive. All rights reserved.</p>
        </footer>
      <!-- </el-col>
    </el-row> -->
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  data(){
    return {
      clientWidth: 1536
    }
  },
  created() {
    this.clientWidth = document.body.clientWidth*0.8
    document.title = 'Openhive | The Power of Nexus'
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {
  padding: 0;
}
header {
  text-align: left;
  position: fixed;
  top: 0;
  background-color: #fff;
  width: 75%;
  z-index: 99;
}
.logo {
  height: 2rem;
  margin-top: 0.5%;
}
header .border {
  height: auto;
  width: 100%;
}
.s_1 {
  margin-top: 3rem;
}
.s_1_img {
  width: 100%;
}
article {
  text-align: left;
  padding: 0 5%;
}
article h2 {
  font-size: 0.8rem;
  color: rgb(21, 31, 109);
  line-height: 2.3rem;
  margin: 0;
  white-space: nowrap;
  font-weight: 700;
}
article p {
  font-size: 0.5rem;
  line-height: 1rem;
  margin-bottom: 0.6rem;
  margin-top: 0;
}
article strong {
  color: #674FFF;
  font-family: Arial, Helvetica, sans-serif;
}
/* .q_text {
  width: 100%;
  font-size: 28px;
  display: block;
  font-weight: 600;
  line-height: 50px;
    quotes: "\201C""\201D""\2018""\2019";
} */
/* .q_text h3 {
    font-size:21px;
} */

.q_content {
  /* padding: 60px 30px;
  background-color: rgb(238, 218, 234);
  position: relative; */
  margin-bottom: 1rem;
  padding: 0 7%;
}
.q_content img {
  width: 100%;
}
/* .q_text:before { 
    content: open-quote;
    font-weight: bold;
    font-size:100px;
    color:#fff;
    position: absolute;
    left: 5px;
    top: 30px;
} 
.q_text:after { 
    content: close-quote;
    font-weight: bold;
    font-size:100px;
    color:#fff;
    position: absolute;
    right: 25px;
    bottom: -20px;
} */
.border {
  width: 100%;
  height: 0.2rem !important;
}
footer {
  margin-top: 1rem;
}
/* footer img {
  height: 60px;
} */
.contact {
  font-size: 0.5rem;
  font-weight: 700;
  margin-top: 0.5rem;
}
.copyright {
  color: #ccc;
  font-size: 0.4rem;
}
@media (max-width: 768px) {
  article h2 {
    font-size: 0.5rem;
  }
}
</style>
